:root {
	--color-primary-light: #f2505d;
	--color-primary: #f24464;
	--color-primary-blue-dark: #092140;
	--color-primary-blue: #0f2f59;
	--color-grey-light-1: #faf9f9;
	--color-grey-light-2: #f4f2f2;
	--color-grey-light-3: #f0eeee;
	--color-grey-light-4: #ccc;
	--color-grey-dark-1: #404040;
	--color-grey-dark-2: #777;
	--color-grey-dark-3: #999;
	--color-red: #A52631;
	--color-green: #207245;
	--shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
	--shadow-light: 0 2rem 5rem rgba(0, 0, 0, 0.06);
	--line: 1px solid var(--color-grey-light-2);
}
* {
	margin: 0 ;
	padding: 0 ;
}
*,
*::before,
*::after {
	box-sizing: inherit;
}
html {
	box-sizing: border-box;
	font-size: 62.5%; 
}
@media only screen and (max-width: 68.75em) {
	html {
		font-size: 50%;
	} 
}
body {
	font-weight: 400;
	line-height: 1.6;
	color: var(--color-grey-dark-2);
	background-color: var(--color-grey-light-3);
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 100vh;
	font-family: 'Open Sans', sans-serif;
}


/* APP */
.app {
	height: 100vh;
	display: flex;
}
.app__side {
	flex: 0 0 35%;
	background-image: radial-gradient(circle,var(--color-primary-blue),var(--color-primary-blue-dark));
	padding: 4.5rem 0;
}
.app__side-top picture {
	padding-left: 4.5rem;
}
.app__side-top picture img {
	width: 10rem;
}
.app__side-container {
	padding: 4.5rem 2.5rem;
	border-bottom: 1px solid rgba(255,255,255,.3);
}
.app__side-container h2 {
	color: #fff;
	font-size: 2rem;
	font-weight: 500;
}
.app__side-container p {
	font-size: 4.5rem;
	color: #fff;
	margin-top: 1rem;
}
.app__side-container p small {
	opacity: .8;
	font-size: 2rem;
}
.app__side-buttons {
	display: flex;
	justify-content: space-between;
	margin-top: 2rem;
}
.app__side-buttons button {
	padding: 2rem;
	color: #fff;
	text-transform: uppercase;
	cursor: pointer;
	outline: none;
	transition: all .5s;
}
.app__side-buttons button:nth-of-type(1) {
	border: 1px solid var(--color-primary);
	color: var(--color-primary);
	background-color: transparent;
}
.app__side-buttons button:nth-of-type(1):hover {
	background-color: var(--color-primary);
	color: #fff;
}
.app__side-buttons button:nth-of-type(2),
.app__side-buttons button:nth-of-type(3) {
	background-color: transparent;
	border: 1px solid #fff;
}
.app__side-buttons button:nth-of-type(2):hover,
.app__side-buttons button:nth-of-type(3):hover {
	background-color: #fff;
	color: var(--color-primary-blue-dark);
}
.app__side-actions {
	padding: 4.5rem 2.5rem;
}
.app__side-actions h2 {
	font-size: 2rem;
	color: #fff;
	font-weight: 500;
}
.app__side-actions ul {
	display: flex;
	flex-direction: column;
	list-style: none;
}
.app__side-actions ul li {
	display: flex;
	margin-top: 2rem;
	padding: 1rem;
	border-radius: 1rem;
	background-color: rgba(255,255,255,.1);
	color: #fff;
	font-size: 1.4rem;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	transition: all .5s;
}
.app__side-actions ul li span small {
	opacity: .5;
}
.app__side-actions ul li:hover {
	background-color: rgba(255,255,255,.2);
}
.app__side-actions picture {
	padding: 2rem;
	display: flex;
	align-items: center;
	justify-content: center;
}
.app__side-actions picture img {
	width: 100%;
	max-width: 30rem;
}
.buttons-container {
	display: flex;
	justify-content: space-around;
}
.buttons-container button {
	padding: 1rem;
	border: 1px solid var(--color-primary);
	color: var(--color-primary);
	background-color: transparent;
	text-transform: uppercase;
	cursor: pointer;
	outline: none;
}
.buttons-container button:hover {
	background-color: var(--color-primary);
	color: var(--color-primary-blue-dark);
}
main {
	flex: 1;
	width: auto;
	background-color: #fff;
	overflow-y: auto;
	padding-top: 8rem;
	display: flex;
	flex-direction: column;
	position: relative;
}
header {
	border-bottom: var(--line);
	padding: 2rem;
	width: 100%;
	position: fixed;
	background-color: #fff;
	z-index: 999;
	top: 0;
}
.header__container {
	display: flex;
}
.header__container a {
	text-align: center;
	font-size: 1.2rem;
	text-transform: uppercase;
	font-weight: 500;
	color: var(--color-grey-dark-1);
	text-decoration: none;
	padding: .5rem 2rem;
	position: relative;
}
.header__container a::after {
	content: "";
	height: 0;
	width: 100%;
	position: absolute;
	bottom: 0;
	background-color: var(--color-primary);
	left: 0;
	transition: all .2s;
}
.header__container i {
	text-align: center;
	font-size: 2rem;
	font-weight: 500;
	color: var(--color-grey-dark-1);
	text-decoration: none;
	padding: .5rem 2rem;
	position: relative;
	cursor: pointer;
}
.header__container i::after {
	content: "";
	height: 0;
	width: 100%;
	position: absolute;
	bottom: 0;
	background-color: var(--color-primary);
	left: 0;
	transition: all .2s;
}
.header__container a:hover::after {
	height: 2px;
}
.header__container i:hover::after {
	height: 2px;
}
table {
	font-size: 1.4rem;
	border-collapse: collapse;
	width: 100%;
}
table th {
	background-color: var(--color-primary-blue);
	color: #fff;
	text-transform: uppercase;
	padding: 1rem;
}
table td {
	color: var(--color-grey-dark-3);
	border-bottom: 1px solid var(--color-primary-blue);
	padding: 1rem;
}
table td.red {
	color: var(--color-red);
}
table td.green {
	color: var(--color-green);
}
.input-container {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	font-size: 1.4rem;
	color: #fff;
	text-transform: uppercase;
}
.input-container input {
	margin-top: 1rem;
	padding: 1rem;
	border: none;
	border-bottom: 1px solid #fff;
	color: #fff;
	background-color: transparent;
	outline: none;
}
.input-container button {
	padding: 1rem;
	border: none;
	background-color: transparent;
	color: #fff;
	text-transform: uppercase;
	cursor: pointer;
	border: 1px solid #fff;
	outline: none;
}
.input-container button:hover {
	background-color: #fff;
	color: var(--color-primary-blue-dark);
}

/* LOG-IN */
.log-in {
	display: flex;
	height: 100vh;
	justify-content: center;
	align-items: center;
	background-color: var(--color-primary-blue-dark);
}
.log-in form {
	width: 100%;
	max-width: 40rem;
	display: flex;
	flex-direction: column;
	background-color: var(--color-primary-blue);
	padding: 2rem;
	box-shadow: var(--shadow-dark);
}

/* HOME */
.home__filter {
	display: flex;
	padding: 2rem;
	align-items: center;
}
.home__filter span {
	margin-right: 1rem;
	font-size: 1.4rem;
	text-transform: uppercase;
	color: var(--color-primary-blue);
	font-weight: 500;
}
.home__filter select {
	margin-right: 1rem;
	outline: none;
	border: none;
	background-color: transparent;
	color: var(--color-primary-blue);
	padding: 1rem;
	border-bottom: 1px solid var(--color-primary-blue);
	border-radius: 0;
}
.home__nav {
	padding: 2rem;
	display: flex;
}
.home__nav ul {
	display: flex;
	list-style: none;
}
.home__nav ul li {
	cursor: pointer;
	margin-right: 2rem;
	padding: 1rem;
	font-size: 1.4rem;
	border-radius: .5rem;
}
.home__nav ul li:nth-of-type(1) {
	color: var(--color-grey-dark-3);
}
.home__nav ul li:nth-of-type(2) {
	color: var(--color-primary-blue);
}
.home__nav ul li:nth-of-type(3) {
	color: var(--color-primary);
}
.home__nav ul li:hover,
.home__nav ul li.active {
	background-color: rgba(0, 0, 0, 0.05)
}
.home__nav-search {
	display: flex;
	align-items: center;
	margin-left: auto;
	padding: .5rem;
	border: 1px solid var(--color-grey-light-3);
	border-radius: 1rem;
	color: var(--color-grey-dark-3);
}
.home__nav-search input {
	border: none;
	outline: none;
	margin-left: 1rem;
	color: var(--color-grey-dark-3);
}
.home__container {
	padding: 0 2rem 4.5rem;
	list-style: none;
}
.home__container li {
	display: flex;
	align-items: center;
	font-size: 1.4rem;
	margin-top: 2rem;
	padding: 2rem;
	background-color: rgba(0, 0, 0, 0.07);
	border-radius: 1rem;
}
.home__container li .content {
	flex: 1;
}
.home__container li select {
	flex: 0 0 20%;
	color: var(--color-primary);
	border: none;
	background-color: transparent;
	outline: none;
	cursor: pointer;
}
.home__container li p {
	flex: 0 0 20%;
	text-align: center;
}
.home__container li p.red {
	color: var(--color-red);
}
.home__container li p.green {
	color: var(--color-green);
}
.home__container li button {
	flex: 0 0 10%;
	border: none;
	background-color: transparent;
	display: flex;
	align-items: center;
	cursor: pointer;
	color: var(--color-primary-blue);
	justify-content: flex-end;
	outline: none;
}

/* EXPENSES */
.expenses>ul {
	list-style: none;
	padding: 2rem;
	width: 100%;
	max-width: 60rem;
	margin: 0 auto;
}
.expenses>ul li {
	display: flex;
	margin-top: 2rem;
	padding: 2rem;
	align-items: center;
	border-radius: 1rem;
	background-color: var(--color-primary-blue);
	color: #fff;
	cursor: pointer;
	transition: all .5s;
}
.expenses>ul li .container {
	margin-left: 2.5rem;
}
.expenses>ul li .container h3 {
	font-weight: 600;
	font-size: 1.4rem;
}
.expenses>ul li .container span {
	font-size: 1rem;
	font-weight: 400;
	color: rgba(255,255,255,.3);
}
.expenses>ul li p {
	margin-left: auto;
	font-size: 1.2rem;
}
.expenses>ul li:hover {
	transform: scale(1.03);
	box-shadow: 2px 2px 4px var(--color-grey-dark-3);
}

/* PAYMENTS */
.payments {
	padding: 2.5rem;
	display: flex;
}
.payments > ul {
	flex: 1;
	margin-right: 2.5rem;
	list-style: none;
}
.payments > ul li {
	padding: 2rem;
	display: flex;
	background-color: var(--color-primary-blue-dark);
	margin-top: 2rem;
	cursor: pointer;
	align-items: center;
	color: #fff;
}
.payments > ul li .container {
	margin-left: 2rem;
}
.payments__container {
	flex: 0 0 50%;
	width: 20rem;
}
.payments__container h2 {
	font-size: 1.4rem;
	color: var(--color-primary-blue-dark);
	border-bottom: 1px solid var(--color-primary-blue-dark);
	letter-spacing: 1px;
	padding: 1rem;
}
.payments__container > ul {
	display: flex;
	overflow-x: auto;
	margin-top: 2rem;
	padding: 2rem;
	list-style: none;
	background-color: var(--color-primary-blue-dark);
	border-radius: 1rem;
	width: 100%;
}
.payments__container > ul li {
	cursor: pointer;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	background-color: rgba(255,255,255,.3);
	margin-right: 2rem;
	padding: 1rem;
	border-radius: 1rem;
	color: #fff;
	justify-content: center;
}
.payments__container > ul li picture {
	overflow: hidden;
	width: 6rem;
	height: 6rem;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #fff;
}
.payments__container > ul li picture img {
	width: 100%;
}

/* HELP */
.help {
	width: 100%;
	padding: 2.5rem;
	display: flex;
	height: 100%;
}
.help__container {
	display: flex;
	flex-direction: column;
	width: 100%;
	max-width: 60rem;
	margin: 0 auto;
}
.help__container ul {
	flex: 1;
	list-style: none;
	overflow-y: auto;
}
.help__container ul li {
	display: flex;
	margin-top: 2rem;
	padding: 1rem;
	background-color: rgba(0,0,0,.1);
	width: 25rem;
	align-items: center;
	border-radius: 1rem;
}
.help__container ul li picture {
	width: 5rem;
	height: 5rem;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 50%;
}
.help__container ul li picture img {
	width: 100%;
}
.help__container ul li .container {
	color: var(--color-grey-dark-1);
	font-size: 1.2rem;
	margin-left: 2rem;
}
.help__container ul li.right {
	margin-left: auto;
	flex-direction: row-reverse;
}
.help__container ul li.right .container {
	margin-left: 0;
	margin-right: 2rem;
	text-align: right;
}
.help__container form {
	display: flex;
	position: relative;
}
.help__container form textarea {
	width: 100%;
	position: relative;
	resize: none;
	border: 1px solid var(--color-grey-dark-3);
	color: var(--color-grey-dark-3);
	border-radius: 1rem;
	padding: 2rem;
	outline: none;
}
.help__container form button {
	position: absolute;
	top: -2.5rem;
	right: 2.5rem;
	width: 5rem;
	height: 5rem;
	border-radius: 50%;
	border: none;
	background-color: var(--color-primary-blue-dark);
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	outline: none;
}